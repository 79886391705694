.ui.button.themed-button-old,
.terms-page .ui.themed-button-old {
  padding: 8px 16px;
  color: #007cb0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background: #fff;
  text-transform: uppercase;
  border: 1px solid #007cb0;
  border-radius: 2px;

  &.transparent-table-button {
    font-weight: normal;
    font-size: 12px;
    color: #007cb0;
    line-height: unset;
    background-color: unset;
    padding: 0;
    text-transform: none;
    border: none;

    &.active,
    &:hover {
      background: none;
      color: #007cb0;
    }
  }
  &.filter-action {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 10px !important;
    line-height: 16px;
    padding: 4px 4px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    background-color: unset;
    border: none;
    margin: 0;

    &:hover {
      color: #007cb0;
    }
  }
  &.filter-action.filled {
    padding: 4px 8px;

    &:hover {
      color: #fff;
    }
  }
  &.filled {
    color: #fff;
    background-color: #007cb0;

    &:disabled {
      background: #a7a8aa;
      border-color: #a7a8aa;
    }
  }

  &.ui.mini.button {
    padding: 4px 16px;
    font-size: 14px;
    line-height: 24px;
  }
}

.ui.disabled.themed-button {
  &.filter-action {
    opacity: 1 !important;
    color: #d0d0ce;
  }
}
