.ui.button.themed-button {
  font-weight: 600;
  border-radius: 2px;
  letter-spacing: 0.5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  line-height: 24px;
  margin-right: 0;

  &__uppercase {
    text-transform: uppercase;
  }

  &__capitalize {
    text-transform: capitalize;
  }

  &.large {
    padding: 8px 16px;
    font-size: 14px;
  }

  &.medium {
    padding: 4px 16px;
  }

  &.small {
    padding: 0 8px;
    font-size: 12px;
  }

  &__solid-blue {
    color: #fff !important;
    background-color: #007cb0;

    &:hover,
    &:focus {
      background-color: #006894;
    }

    &:active {
      background-color: #005478;
    }

    &:disabled {
      background-color: #a7a8aa;
      opacity: 1 !important;
    }
  }

  &__text-blue,
  &__with-icon {
    color: #007cb0;
    background-color: #fff;

    &:hover,
    &:focus {
      color: #007cb0;
      background-color: #ebf5f9;
    }

    &:active {
      background-color: #d6eaf2;
    }

    &:disabled {
      color: #a7a8aa;
    }
  }

  &__text-red {
    color: #da291c;
    background-color: #fff;

    &:hover,
    &:focus {
      color: #da291c;
      background-color: #fceeed;
    }

    &:active {
      background-color: #fde7e5;
    }

    &:disabled {
      color: #a7a8aa;
    }
  }

  &__with-icon {
    display: flex;
    align-items: center;

    &__left {
      &.large,
      &.medium {
        padding-left: 8px;
      }

      &.small {
        padding-left: 0;
      }
    }

    &__right {
      flex-direction: row-reverse;

      &.large,
      &.medium {
        padding-right: 8px;
      }

      &.small {
        padding-right: 0;
      }
    }

    & svg,
    & path {
      fill: #007cb0;
    }

    &:disabled {
      color: #a7a8aa;

      & svg,
      & path {
        fill: #a7a8aa;
      }
    }
  }

  .themed-button__icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
  }

  &__outline-grey {
    color: #000;
    border: 1px solid #a7a8aa;
    background-color: #fff;

    &.large {
      padding: 7px 16px;
    }

    &.medium {
      padding: 3px 16px;
    }

    &.small {
      padding: 0 8px;
      line-height: 22px;
    }

    &:hover,
    &:focus {
      background-color: #ebebeb;
    }

    &:active {
      background-color: #d6d6d6;
      border: 1px solid #d0d0ce;
    }

    &:disabled {
      color: #a7a8aa;
      border: 1px solid #a7a8aa;
    }
  }

  &__outline-blue {
    color: #007cb0;
    border: 1px solid #007cb0;
    background-color: #fff;

    &.large {
      padding: 7px 16px;
    }

    &.medium {
      padding: 3px 16px;
    }

    &.small {
      padding: 0 8px;
      line-height: 22px;
    }

    &:hover,
    &:focus {
      color: #006894;
      background-color: #ebf5f9;
    }

    &:active {
      background-color: #d6eaf2;
    }

    &:disabled {
      color: #a7a8aa;
      border: 1px solid #a7a8aa;
    }
  }

  &__solid-red {
    color: #fff;
    background-color: #da291c;

    &:hover {
      background-color: #c82518;
    }

    &:active {
      background-color: #b62116;
    }

    &:disabled {
      background-color: #a7a8aa;
    }
  }
}
