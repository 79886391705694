.ui.inverted.dimmer {
  position: absolute;
  right: 0;
  left: 0;
  top: 112px !important;
  height: calc(100vh - 112px);
  bottom: 0;
  background-color: unset !important;

  &.full-screen-loader {
    top: 0 !important;
    height: 100vh;
    background-color: rgba(255,255,255,.85) !important;
    z-index: 1002;
  }
}