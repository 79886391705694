.memo-page-wrapper {
  position: relative;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;

  &__app-name {
    padding: 4px 48px;
    background-color: #e9e9e9;
    text-align: left;
    font-size: 12px;
    line-height: 24px;
  }

  &__content {
    min-height: calc(100% - 80px);
    padding-left: 48px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.memo-page-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}
