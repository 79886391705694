.top-header {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 48px;
    z-index: 101;
    flex-shrink: 0;
  
    &__logo {
      margin-right: 32px;
    }
  
    &__nav {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  