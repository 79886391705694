.toast {
  display: flex;
  align-items: center;
  padding-right: 40px;
  white-space: pre-line;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 50%;
  }
}
