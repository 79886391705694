body {
  background-color: #ebebeb;
}

.main-segment {
  margin-top: 8rem !important;
}

.text-align-justify {
  text-align: justify !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left;
}

.word-wrap-break {
  word-wrap: break-word;
}

.align-center {
  display: table !important;
  float: none !important;
  margin: 0 auto !important;
}

/** Margins **/
.margin-bottom {
  margin-bottom: 25px !important;
}

.margin-top {
  margin-top: 25px !important;
}

.margin-right {
  margin-right: 25px !important;
}

.margin-left {
  margin-left: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-top-50 {
  margin-top: 70px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-bottom-15 {
  margin-left: 15px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.padding-5 {
  padding: 5px;
}

.paper {
  background: #FFFFFF;
}

.padding-2 {
  // excess 0.2px is for maintaining the everlasting balance of the universe
  padding: 2.2px;
}

.padding-top-5 {
  padding-top: 5px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.pull-left {
  float: left;
}

.no-display {
  display: none !important;
}

.no-background {
  background: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* COLORS */
.bg-color-green {
  background-color: #81b623 !important;
}

.bg-color-red {
  background-color: #d51f1f !important;
}

.def-weight {
  font-weight: unset;
}

.color-green {
  color: #81b623 !important;
}

.color-red {
  color: #d51f1f !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.inline-block {
  display: inline-block;
}

.long-value-input:hover:after {
  cursor: pointer;
}

.hover-pointer:hover {
  cursor: pointer;
}

.ui.input.error input {
  border-color: #e0b4b4;
  color: #9f3a38;
}

.tooltip {
  display: none;
  max-height: 130px;
  background-color: #000000;
  padding: 16px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 2px;
  overflow-y: auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fff;

  &-lg {
    width: 40%;
  }

  &-md {
    width: 30%;
  }

  &-sm {
    width: 20%;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #6c757d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.tooltip:hover {
  display: block;
  opacity: 0.9;
}

.long-value-input:hover + .tooltip {
  display: block;
  opacity: 0.9;
}

.cursor-pointer {
  cursor: pointer !important;
}

.modal {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  height: auto;
  overflow: visible;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2185d0 !important;
  border-color: #2185d0 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.reset-button {
  background-color: transparent;
  border-width: 0;
  padding: 0;
  display: inline-block;
  font-family: inherit;
}

.required-star::after {
  margin-left: 4px;
  display: inline;
  content: '*';
  color: #da291c;
}

.memo-link {
  color: #007cb0;

  &:hover {
    text-decoration: underline;
  }
}

.field {
  &-error-text {
    font-size: 12px;
    color: #da291c;
    padding-top: 8px;
    line-height: 16px;
  }

  &-wrapper {
    & + & {
      margin-top: 16px;
    }
  }
}
