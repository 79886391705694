.app {
  &__toast-close-btn {
    position: absolute;
    top: 23px;
    right: 22px;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 1px;
      content: '';
      background-color: #000;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

:root {
  --toastify-toast-width: 400px;
}
