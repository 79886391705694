.memo-footer {
  margin-top: auto;
  color: #63666a;
  background-color: #f5f5f4;
  padding: 32px 48px;
  font-size: 14px;
  line-height: 20px;

  & a {
    color: #007cb0;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__copyright {
    margin-bottom: 16px;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;

    & a {
      color: #63666a;
      text-decoration: none;

      &:hover {
        color: #007cb0;
        text-decoration: underline;
      }
    }
  }
}
