.logout-warning-popup {
  &.ui.basic.modal {
    position: relative;
    padding: 24px;
    max-width: 480px;
    background-color: #fff;
    color: #000;
    border-radius: 2px;
    overflow: hidden;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
    background: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &__close-icon {
    width: 100%;
    height: 100%;

    line {
      stroke: #000;
    }
  }

  &__message {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__button {
    &.ui.button {
      padding: 8px 16px;
      color: #007cb0;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #fff;
      text-transform: uppercase;
      border: 1px solid #007cb0;
      border-radius: 2px;
    }

    &_filled.ui.button {
      color: #fff;
      background-color: #007cb0;
    }

    &.ui.button:not(:last-child) {
      margin-right: 16px;
    }
  }
}
