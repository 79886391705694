.user-menu {
  position: relative;
  height: 100%;
  text-align: left;

  &__toggle {
    padding: 8px;
    width: 48px;
    border: none;
    height: 100%;
    background: transparent;
    transition: 0.3s;
  }

  &__toggle-text {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: thin solid #D0D0CE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #000;
  }

  &__dropdown {
    position: absolute;
    bottom: 0;
    right: 0;
    transform-origin: right;
    transform: translateY(100%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 248px;
    background: #fff;
    display: none;
    transition: 0.3s transform;

    &--active {
      display: block;
    }
  }

  &__item {
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    &:first-child {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  &__name {
    margin-bottom: 4px;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__email {
    font-size: 12px;
    line-height: 16px;
    color: #A7A8AA;
    margin: 0;
    letter-spacing: 0.5px;
  }

  &__contact-support-icon {
    margin-right: 8px;
  }

  &__contact-support-link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    padding: 0 12px;
    letter-spacing: 0.5px;

    &:hover {
      color: #000;
    }
  }

  &__logout-btn {
    display: block;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    height: 32px;
    border: none !important;
    margin: 0 !important;
    padding: 4px 16px !important;
  }
}
