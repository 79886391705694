.sidebar-menu {
  width: 48px;
  position: absolute;
  bottom: 0;
  height: calc(100% - 80px);
  background-color: #fff;
  z-index: 101;
  transition: width 0.4s;
  border-right: 1px solid #d9d9d9;

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__menu-button {
    position: relative;
    padding-left: 18px;
    height: 48px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
  }

  &__hamburger {
    position: relative;
    width: 15px;
    height: 10px;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #0f0f10;
      transition: 0.3s;

      &:nth-child(2) {
        top: 5px;
      }

      &:nth-child(3) {
        top: 10px;
      }
    }

    &_close {
      & > div {
        &:nth-child(1) {
          transform: translateY(5px) rotate(45deg);
        }

        &:nth-child(2) {
          transform: translateX(-100%);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
    }
  }

  &__nav-list {
    position: absolute;
    height: calc(100% - 48px);
    top: 48px;
    border-right: 1px solid #d9d9d9;
    transition: width 0.4s;
    width: 48px;
    background-color: #fff;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      display: block;
      width: 0;
      height: 1px;
      z-index: 1;
      background-color: #d9d9d9;
      transition: width 0.4s;
    }

    &--opened {
      width: 348px;

      &::before {
        width: 300px;
      }
    }
  }

  &__action {
    position: relative;
    height: 48px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: background-color 0.3s;

    & > svg > path {
      transition: 0.3s;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 3px;
      height: 100%;
      content: '';
      opacity: 0;
      background: #0076a8;
      transition: 0.3s;
    }

    &:hover {
      background: #f7fcfe;

      &::before {
        opacity: 1;
      }

      & > svg > path {
        fill: #0076a8;
      }
    }

    &_active {
      background: #f7fcfe;

      &::before {
        opacity: 1;
      }

      & > svg > path {
        fill: #0076a8;
      }
    }
  }

  &__item-name {
    margin-left: 12px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    opacity: 0;
    animation: fade-in-name 0.1s ease-out forwards;
  }
}

@keyframes fade-in-name {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
